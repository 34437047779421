<template>
<v-footer
    class="py-4 topFooter"
    dark
    height="auto"
  >
    <v-container class="mx-auto py-0 full_width">
      <v-row align="center">
        <router-link
        to="/">
          <v-img
            :src="require('@/assets/white-logo.png')"
            class="mr-5"
            contain
            @click="$vuetify.goTo(0)"
          />
        </router-link>

        <v-spacer></v-spacer>
        <div>
          <p class="phoneFooter">Call our <strong>Customer Service Team</strong> on <span><a href="tel:03456011050">0345 601 1050 <i aria-hidden="true" class="fa fa-phone"></i></a></span></p>
          <p class="openTimes">Monday - Friday <strong>9am - 7pm</strong>, Saturday <strong>8am - 2pm</strong></p>
        </div>

      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreAppBar',
  }
</script>
